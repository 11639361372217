const dashBoardModule = {
    namespaced: true,
    state: {
        totalSMSLast30days: localStorage.getItem("dashBoardData"),
        timestamp: localStorage.getItem("dashBoardDataTimestamp"), // To store the time when data was set
    },
    mutations: {
        setTotalSMSLast30days(state, dashBoardData) {
            state.totalSMSLast30days = dashBoardData;
            localStorage.setItem("dashBoardData", dashBoardData); // Ensure this is being set in localStorage
            localStorage.setItem("dashBoardDataTimestamp", new Date().getTime()); // Store timestamp as well
        },
        clearTotalSMSLast30days(state) {
            state.totalSMSLast30days = null;
            state.timestamp = null;
            localStorage.removeItem("dashBoardData");
            localStorage.removeItem("dashBoardDataTimestamp");
        },
    },
    actions: {
        setTotalSMSLast30days({ commit }, dashBoardData) {
            commit("setTotalSMSLast30days", dashBoardData);
        },
        checkDataExpiry({ commit, state }) {
            const currentTime = new Date().getTime();
            const storedTimestamp = state.timestamp;

            // Check if 24 hours have passed (24 hours = 86400000 milliseconds)
            if (storedTimestamp && currentTime - storedTimestamp > 86400000) {
                commit("clearTotalSMSLast30days");
            }
        },
    },
    getters: {
        getTotalSMSLast30days: (state) => {
            return state.totalSMSLast30days != null ? state.totalSMSLast30days : "0";
        },
    },
};

export default dashBoardModule;





