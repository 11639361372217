// i18n.js
import { createI18n } from 'vue-i18n';


let messages = {
    en: require('@/assets/locales/en.json'),
    ba: require('@/assets/locales/bn.json')
    // Add more languages as needed
};
const i18n = createI18n({
    locale: 'en', // Default locale
    messages
});

export {i18n, messages};
