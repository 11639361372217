import axios from "axios";


let BASE_API_URL = "";
let BILLING_URL = "";
let PORTAL_NAME = "";
let COMPANY_NAME = "";

class ConfigService {
    async getConfiguration() {
        await axios.get(process.env.BASE_URL + "config.json").then(response => {
            BASE_API_URL = response.data.BASE_API_URL;
            BILLING_URL = response.data.BILLING_URL;
            PORTAL_NAME = response.data.PORTAL_NAME;
            COMPANY_NAME = response.data.COMPANY_NAME;
        });
    }

    async getBillingUrl() {
        if (BILLING_URL === null || BILLING_URL === '') {
            await this.getConfiguration();
        }
        return BILLING_URL;
    }

    async getBaseAPIUrl() {
        if (BASE_API_URL === null || BASE_API_URL === '') {
            await this.getConfiguration();
        }
        return BASE_API_URL;
    }

    async getPortalName() {
        if (PORTAL_NAME === null || PORTAL_NAME === '') {
            await this.getConfiguration();
        }
        return PORTAL_NAME;
    }

    async getCompanyName() {
        if (COMPANY_NAME === null || COMPANY_NAME === '') {
            await this.getConfiguration();
        }
        return COMPANY_NAME;
    }
}

export default new ConfigService()
