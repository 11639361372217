
const themeModule = {
    namespaced: true,
    state: {
        themeClass: localStorage.getItem("mode")
    },
    mutations: {
        setTheme(state, theme) {
            state.themeClass = theme;
            localStorage.setItem("mode", theme);
        },
    },
    actions: {
        changeTheme({commit}, theme){
            commit('setTheme', theme);
        },
    },
    getters: {
        themeClass: (state) => {
            return () =>{
                if(state.themeClass == undefined || state.themeClass== null || state.themeClass.length==0){
                    return 'theme-light';
                }
                return "theme-"+state.themeClass;
            }
        },
        getTheme(state){
            return () =>{
                if(state.themeClass == undefined || state.themeClass== null || state.themeClass.length==0){
                    return 'light';
                }
                return state.themeClass;
            }
        }
    },
};

export default themeModule;

