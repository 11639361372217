import {createStore} from 'vuex'
import authModule from './modules/auth'
import formModule from "@/store/modules/form";
import themeModule from "@/store/modules/theme";
import dashBoardModule from "@/store/modules/dashboard";


const store = createStore({
    // Other store configuration...
    modules: {
        auth: authModule,
        form: formModule,
        theme: themeModule,
        dashboard: dashBoardModule
    }
});
export default store;
